import { NgModule } from "@angular/core";
import { MaterialModule } from "./material.module";
import { CommonModule } from "@angular/common";
import { ProgressModule } from "./progress";
import { RouterModule } from "@angular/router";
import { ConfirmModule } from "./confirm";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularEditorModule } from "@kolkov/angular-editor";

@NgModule({
    providers: [],
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ProgressModule,
        ConfirmModule,
        ReactiveFormsModule,
        FormsModule,
        AngularEditorModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ProgressModule,
        ConfirmModule,
        ReactiveFormsModule,
        FormsModule,
        AngularEditorModule,
    ]
})
export class SharedModule { }