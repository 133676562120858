import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '..';
import { ConfirmComponent, ConfirmDeleteComponent } from './components';

@NgModule({
    providers: [],
    declarations: [ConfirmDeleteComponent, ConfirmComponent,],
    imports: [CommonModule, MaterialModule,],
    exports: [CommonModule, ConfirmDeleteComponent, ConfirmComponent,],
})
export class ConfirmModule { }
